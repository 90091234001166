<template>
  <el-card class="image-item" :body-style="{ padding: '0px' }">
    <img :src="item.url" :alt="item.name" />
    <div class="btn-right mt-2">
      <el-button @click="copyToKeyboard">Copy link</el-button>
    </div>
  </el-card>
</template>

<script>

export default {
  name: 'ImageItem',
  props: ['item'],
  methods: {
    copyToKeyboard() {
      navigator.clipboard.writeText(this.item.url);
      this.$message.success('Copied to clipboard');
    },
  }
};
</script>

<style lang="scss">
.image-item {
  height: 250px;
  .btn-right {
    padding-right: 1rem;
  }
  img {
    width: 100%;
    height: 200px;
    display: block;
    object-fit: cover;
  }
  button {
    &:hover {
      background-color: rgba($primary-light, 0.2);
      border: 1px solid $primary;
      span {
        color: $primary;
      }
    }
  }
}
</style>

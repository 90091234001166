<template>
  <div class="container-fluid mt-4 mb-4" v-loading="isLoading">
    <div class="row mb-4">
      <div class="col-12 col-lg-6">
        <button class="btn-sm-accent" @click="showModal = true">Add new</button>
      </div>
      <div class="col-12 col-lg-6 btn-right">
        <app-pagination
          :total="total"
          :current-page="page"
          :page-size="pageSize"
          @page-change="handlePageChange"
        />
      </div>
    </div>
    <div class="row" v-if="items?.length && !isLoading">
      <div
        class="col-12 col-mg-6 col-lg-4 col-xl-3 mb-4"
        v-for="item in items"
        :key="item.name"
      >
        <image-item :item="item" />
      </div>
    </div>
  </div>

  <el-dialog v-model="showModal" title="Add new image" width="50%">
    <div class="">
      <el-upload
        :file-list="fileList"
        :limit="10"
        :auto-upload="false"
        :on-change="handleUpload"
        :multiple="true"
        list-type="picture-card"
      >
        <template #trigger>
          <el-button type="default">Select file</el-button>
        </template>
        <template #tip>
          <div class="el-upload__tip text-red">
            Limited to 10 files at once, only jpg/png files are allowed.
          </div>
        </template>
      </el-upload>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button @click="addNew"> Add new </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import Database from '@/utils/services/Database';
import ImageItem from '@/components/items/Image';

export default {
  name: 'AdminImages',
  components: {
    ImageItem,
  },
  data() {
    return {
      items: [],
      total: 1,
      page: 1,
      pageSize: 12,
      isLoading: false,
      showModal: false,
      fileList: [],
      showUpload: true,
    };
  },
  async created() {
    await this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      const res = await Database.getAll('uploads', {
        limit: this.pageSize,
        offset: this.page * this.pageSize - this.pageSize,
      });

      this.items = res.data;
      this.total = res.total;
      this.isLoading = false;
    },
    async handlePageChange(val) {
      this.page = val;
      await this.getData();
    },
    async addNew() {
      // Show loader while uploading
    
      console.log(this.fileList);

      for (let i in this.fileList) {
        const file = this.fileList[i].raw;
        await this.uploadImage(file);
      }

      // Hide upload loader
      this.showModal = false;
      await this.getData();
    },
    handleUpload(file) {
      console.log(file)
      const fileType = file.raw.type;

      if (fileType !== 'image/jpeg' && fileType !== 'image/png') {
        return Utilities.showMessage(
          'error',
          'Only jpg/png files are allowed.'
        );
      }
    },
    async uploadImage(file) {
      try {
        await Database.upload('news', file);
        this.$message.success('Image(s) uploaded successfully');
      } catch (err) {
        console.log(err)
        this.$message.error('Error uploading image')
      }
    }
  },
};
</script>
